<script>
    import { HeadsetProvider } from '@providers/HeadsetProvider.js'
    import { mapState } from 'pinia'
    import { useCurrentCompanyViewed } from '@store/currentCompanyViewed.js'
    import { Headset } from '@libs/MDM/core/Headset.js'
    /* eslint-disable vue/no-mutating-props */
    export default {
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },
        data() {
            return {
                edit: false,
                errors: {},
            }
        },
        computed: {
            ...mapState(useCurrentCompanyViewed, ['companyID']),
        },
        methods: {
            toggleEdit() {
                this.edit = !this.edit
            },
            async handleSave() {
                await HeadsetProvider.update(this.companyID, this.headset.id, {
                    notes: this.headset.state.notes,
                })
                    .bindVue(this)
                    .call()
                if (Object.keys(this.errors).length === 0) {
                    this.edit = false
                }
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="4"
    >
        <ra-card>
            <template #title>
                {{ $tc('headsets.properties.note.label', 2) }}
            </template>
            <template #right>
                <ra-icon
                    v-if="!edit"
                    weight="bold"
                    class="clickable"
                    @click="toggleEdit"
                    >fa-edit</ra-icon
                >
                <ra-typo
                    v-else
                    span
                    underline
                    class="clickable"
                    @click="toggleEdit"
                    >{{ $t('generics.cancel') }}</ra-typo
                >
            </template>
            <ra-stack padding="3">
                <template v-if="!edit">
                    <ra-typo>
                        {{
                            headset.state.notes ||
                            $t('headsets.properties.note.no_note')
                        }}
                    </ra-typo>
                </template>

                <template v-else>
                    <ra-input
                        name="notes"
                        :errors="errors"
                    >
                        <v-textarea
                            v-model="headset.state.notes"
                            hide-details
                            density="comfortable"
                            variant="outlined"
                        />
                    </ra-input>
                    <ra-stack direction="horizontal">
                        <ra-spacer />
                        <v-btn
                            variant="flat"
                            color="primary-gradient"
                            @click="handleSave"
                        >
                            {{ $t('generics.save') }}
                        </v-btn>
                    </ra-stack>
                </template>
            </ra-stack>
        </ra-card>
    </ra-stack>
</template>

<style scoped lang="scss"></style>

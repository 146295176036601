<script>
    import Help from '@ui/Help.vue'
    import { Headset } from '@libs/MDM/core/Headset.js'
    import All from '@assets/images/MDM/learnerAndUse.png'
    import Free from '@assets/images/MDM/use.png'
    import Guided from '@assets/images/MDM/learner.png'

    export default {
        components: {
            Help,
        },
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },

        computed: {
            settings: {
                get() {
                    return this.headset.settings.value
                },
                set(value) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.headset.settings.value = value
                },
            },
            image() {
                let images = {
                    all: All,
                    free: Free,
                    guided: Guided,
                }
                return images[this.settings.launchTraining]
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="6"
    >
        <ra-card>
            <template #title>
                <ra-typo semi>Mode de lancement de formation</ra-typo>
            </template>
            <ra-stack
                gap="4"
                padding="4"
            >
                <ra-tab-select
                    v-model="settings.launchTraining"
                    :options="[
                        {
                            label: $t(
                                'headsets.settings.headset_formation_settings.start_mode.values.all'
                            ),
                            icon: 'fa-learners',
                            value: 'all',
                            iconOptions: {
                                weight: 'bold',
                            },
                        },
                        {
                            label: $t(
                                'headsets.settings.headset_formation_settings.start_mode.values.free'
                            ),
                            icon: 'fa-eye',
                            value: 'free',
                            iconOptions: {
                                weight: 'bold',
                            },
                        },
                        {
                            label: $t(
                                'headsets.settings.headset_formation_settings.start_mode.values.learner'
                            ),
                            icon: 'fa-graduation-cap',
                            value: 'guided',
                            iconOptions: {
                                weight: 'bold',
                            },
                        },
                    ]"
                />

                <ra-stack
                    direction="horizontal"
                    bg="vr-purple"
                    padding="4"
                    rounded
                    alignement="center"
                    mobile-swap
                >
                    <ra-typo>
                        <span
                            v-html="
                                $t(
                                    'headsets.settings.headset_formation_settings.start_mode.disclaimers.all'
                                )
                            "
                        />
                    </ra-typo>
                    <ra-image
                        :src="image"
                        width="250px"
                    />
                </ra-stack>
            </ra-stack>
        </ra-card>
        <ra-card>
            <template #title>
                {{
                    $t(
                        'headsets.settings.headset_formation_settings.choice_module.title'
                    )
                }}
            </template>
            <ra-box padding="4,6">
                <v-switch
                    v-model="settings.learnerCanChooseModule"
                    color="primary"
                    density="compact"
                    hide-details
                    :label="
                        $t(
                            'headsets.settings.headset_formation_settings.choice_module.label'
                        )
                    "
                />
            </ra-box>
        </ra-card>
        <ra-card>
            <template #title>
                {{
                    $t(
                        'headsets.settings.headset_formation_settings.quiz.label'
                    )
                }}
            </template>
            <ra-box padding="4,6">
                <v-switch
                    v-model="settings.quiz.vr"
                    color="primary"
                    density="compact"
                    hide-details
                    :label="
                        $t(
                            'headsets.settings.headset_formation_settings.quiz.vr_quiz'
                        )
                    "
                />
                <v-switch
                    v-model="settings.quiz.el"
                    color="primary"
                    density="compact"
                    hide-details
                    :label="
                        $t(
                            'headsets.settings.headset_formation_settings.quiz.el_quiz'
                        )
                    "
                />
            </ra-box>
        </ra-card>

        <ra-card>
            <template #title>
                {{ $t('headsets.settings.headset_synthesis_setting.title') }}
            </template>
            <ra-box padding="4,6">
                <v-switch
                    v-model="settings.sendSynthesisSheet"
                    color="primary"
                    density="compact"
                    hide-details
                    :label="
                        $t('headsets.settings.headset_synthesis_setting.label')
                    "
                />
            </ra-box>
        </ra-card>

        <ra-card>
            <template #title>
                {{ $t('headsets.settings.headset_auto_update.title') }}
            </template>
            <template #right>
                <help>
                    <p
                        v-html="
                            $t(
                                'headsets.settings.headset_auto_update.tooltip_app'
                            )
                        "
                    />
                </help>
            </template>
            <ra-box padding="4,6">
                <ra-stack
                    direction="horizontal"
                    alignement="center"
                >
                    <ra-box>
                        <v-switch
                            v-model="settings.update.formation"
                            color="primary"
                            density="compact"
                            hide-details
                            :label="
                                $t(
                                    'headsets.settings.headset_auto_update.label_formations'
                                )
                            "
                        />
                    </ra-box>
                    <help>
                        <p
                            v-html="
                                $t(
                                    'headsets.settings.headset_auto_update.tooltip_formations'
                                )
                            "
                        />
                    </help>
                </ra-stack>
                <ra-stack
                    direction="horizontal"
                    alignement="center"
                >
                    <ra-box>
                        <v-switch
                            v-model="settings.update.app"
                            color="primary"
                            density="compact"
                            hide-details
                            :label="
                                $t(
                                    'headsets.settings.headset_auto_update.label_app'
                                )
                            "
                        />
                    </ra-box>
                    <help>
                        <p
                            v-html="
                                $t(
                                    'headsets.settings.headset_auto_update.tooltip_app'
                                )
                            "
                        />
                    </help>
                </ra-stack>
            </ra-box>
        </ra-card>
        <ra-box>
            <v-btn
                color="primary-gradient"
                variant="flat"
                @click="headset.settings.apply()"
            >
                {{ $t('generics.save') }}
            </v-btn>
        </ra-box>
    </ra-stack>
</template>

<style scoped lang="scss"></style>

<script>
    import Callout from '@ui/Callout.vue'

    export const FORMATION_FILTERS = {
        RA: 'ra',
        CUSTOM: 'custom',
    }

    export default {
        components: { Callout },
        data() {
            return {
                edit: false,
                filterFormation: FORMATION_FILTERS.RA,
            }
        },

        methods: {
            toggleEdit() {
                this.edit = !this.edit
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="6"
    >
        <Callout>
            {{ $t('headsets.formations_library.callout') }}
        </Callout>
        <ra-stack
            direction="horizontal"
            alignement="center"
            distribution="between"
        >
            <ra-box
                flex="0 1 350px"
                :style
            >
                <ra-tab-select
                    v-model="filterFormation"
                    density="comfortable"
                    :options="[
                        {
                            label: $t(
                                'headsets.formations_library.filter.formation_ra'
                            ),
                            value: 'ra',
                        },
                        {
                            label: $t(
                                'headsets.formations_library.filter.formation_custom'
                            ),
                            value: 'custom',
                        },
                    ]"
                />
            </ra-box>
            <ra-typo
                underline
                class="clickable text-right"
                @click="toggleEdit"
            >
                {{ !edit ? $t('generics.edit') : $t('generics.cancel') }}
            </ra-typo>
        </ra-stack>

        <ra-stack
            v-if="edit"
            direction="horizontal"
            distribution="between"
            mobile-swap
        >
            <v-btn
                color="primary"
                variant="outlined"
            >
                <ra-stack direction="horizontal">
                    <ra-icon>mdi-plus</ra-icon>
                    {{
                        $t('headsets.formations_library.actions.add_formation')
                    }}
                </ra-stack>
            </v-btn>

            <v-btn
                color="primary-gradient"
                variant="flat"
            >
                <ra-stack direction="horizontal">
                    <ra-icon>mdi-plus</ra-icon>
                    {{ $t('headsets.formations_library.actions.save') }}
                </ra-stack>
            </v-btn>
        </ra-stack>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
